import React, { useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';

import './UserStatus.scss';
import { Icon, ICONS } from 'components/Icons';
import { renderCheck } from 'routes/Portfolio/Component/ActivityTab/TopicsTab/helper';
import moment from 'moment';
import InlinePopoverCard from './InlinePopoverCard/InlinePopoverCard';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';

const statusMap = {
  online: {
    color: 'av-green',
    message: 'All systems go',
    icon: ICONS.ONLINE,
  },
  offline: {
    color: 'av-red',
    message: 'Some issues to fix',
    icon: ICONS.OFFLINE,
  },
  warning: {
    color: 'av-camel',
    message: 'Not up and running',
    icon: ICONS.WARNING,
  },
};

const loggedStatusMap = {
  '24h': {
    message: 'You logged an article within the last 24 hours',
    color: 'av-green',
  },
  '3d': {
    message: 'You haven’t logged an article in over 24 hours',
    color: 'av-camel',
  },
  notLogged: {
    message: 'You haven’t logged an article in over 3 days',
    color: 'av-red',
  },
};

const allSettingsStatusMap = {
  enabled: {
    message: 'All features enabled',
    color: 'av-green',
  },
  someDisabled: {
    message: 'Some features are disabled',
    color: 'av-camel',
  },
  disabled: {
    message: 'All features are disabled',
    color: 'av-red',
  },
};

function UserStatus({ userObject, currentUser, isProfile, actions }) {
  const user = userObject.user;
  const isOwner = user && user._id === currentUser._id;
  const { firstName, lastName } = user.profile;
  const userName = isOwner ? '' : `${firstName} ${lastName}`;

  const [show, setShow] = useState(false);

  const inactiveTimeContainer = useRef();

  const profile = user.profile;
  const lastLoggedTime = user.lastLoggedTime;
  const extensions = user.siteInfo.extensions || [];
  const latestExtension = extensions.sort((ext2, ext1) => {
    return ext2.time - ext1.time;
  });
  const isInstalled = latestExtension.find(
    (ext) => ext && ext.installed && ext.installed.time,
  );
  const isLoggedIn = latestExtension.find(
    (ext) => ext && ext.login && ext.login.time,
  );
  const isPinnedIn = latestExtension.find(
    (ext) => ext && ext.pinned && ext.pinned.time,
  );
  const sourceSettingsAnalyzeAll = profile.sourceSettingsAnalyzeAll;
  const sourceSettingsViewWithPoints = profile.sourceSettingsViewWithPoints;
  const sourceSettingsReadingWidget = profile.sourceSettingsReadingWidget;
  const vocabularyEnabled = profile.vocabularyEnabled;
  const settings = [
    sourceSettingsAnalyzeAll,
    sourceSettingsViewWithPoints,
    sourceSettingsReadingWidget,
  ];

  let loggedStatus;
  if (moment(lastLoggedTime).isAfter(moment().subtract(1, 'day'))) {
    loggedStatus = '24h';
  } else if (moment(lastLoggedTime).isAfter(moment().subtract(3, 'day'))) {
    loggedStatus = '3d';
  } else {
    loggedStatus = 'notLogged';
  }

  let allSettingsStatus;
  if (settings.every((setting) => setting)) {
    allSettingsStatus = 'enabled';
  } else {
    allSettingsStatus = 'disabled';
  }

  const checkedInToday =
    latestExtension[0] && latestExtension[0].time
      ? moment(latestExtension[0].time).isAfter(moment().subtract(1, 'day'))
      : false;

  let userStatus;
  if (
    !isLoggedIn ||
    !isPinnedIn ||
    !checkedInToday ||
    loggedStatus === 'notLogged'
  ) {
    userStatus = 'offline';
  } else if (allSettingsStatus === 'disabled' || loggedStatus === '3d') {
    userStatus = 'warning';
  } else {
    userStatus = 'online';
  }

  function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();

    setShow(true);
  }

  function updateSettings(type, checked) {
    actions.updateUserProfile({
      [type]: checked,
    });
  }

  return (
    <div
      className={`user-status-container ${
        !isProfile ? 'relative' : 'absolute'
      }`}
    >
      <div
        className={`user-status ${userStatus}`}
        ref={inactiveTimeContainer}
        onClick={handleClick}
      >
        <InlinePopoverCard
          show={show}
          onHide={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setShow(false);
          }}
          placement="bottom"
          title={statusMap[userStatus].message}
          icon={statusMap[userStatus].icon}
          target={inactiveTimeContainer}
        >
          <PopoverContent
            user={user}
            isLoggedIn={isLoggedIn}
            isPinnedIn={isPinnedIn}
            latestExtension={latestExtension}
            loggedStatus={loggedStatus}
            checkedInToday={checkedInToday}
            isProfile={isProfile}
            isOwner={isOwner}
            userName={userName}
            updateSettings={updateSettings}
          />
        </InlinePopoverCard>
      </div>
    </div>
  );
}

const StatusRow = ({ title, value, linkTo, handleChange }) => (
  <UserStatusRow
    title={title}
    value={value}
    linkTo={linkTo}
    handleChange={handleChange}
  />
);

// Component for rendering extension status rows
const ExtensionStatusRow = ({ title, condition, linkTo, action, userName }) => (
  <StatusRow
    title={title}
    value={condition}
    linkTo={
      condition ? (
        <>
          {userName ? `${userName} has` : 'You have'}{' '}
          {userName ? (
            <>{action} </>
          ) : (
            <Link to={linkTo} className="font-italic">
              {action}{' '}
            </Link>
          )}
          {!userName && 'your'} extension
        </>
      ) : (
        <>
          {userName || 'You'} should{' '}
          {userName ? (
            <>{action} </>
          ) : (
            <Link to={linkTo} className="font-italic">
              {action}{' '}
            </Link>
          )}
          {!userName && 'your'} extension
        </>
      )
    }
  />
);

// Component for rendering feature settings rows
const FeatureRow = ({ title, setting, isOwner, handleChange }) => (
  <StatusRow
    title={title}
    value={setting}
    linkTo={
      setting || !isOwner ? null : (
        <>
          Turn this feature on in your <Link to="/settings">settings</Link>
        </>
      )
    }
    handleChange={isOwner ? handleChange : null}
  />
);

const PopoverContent = ({
  user,
  isLoggedIn,
  isPinnedIn,
  latestExtension,
  loggedStatus,
  checkedInToday,
  isOwner,
  userName,
  updateSettings,
}) => {
  const latestVersion =
    (latestExtension.find((ext) => ext.version) || {}).version || '-';

  return (
    <React.Fragment>
      <div className="division"></div>
      <div className="row pb-2 goal-header font-weight-600">
        <div className="col-md-12">Browser extension status</div>
      </div>

      <ExtensionStatusRow
        title="Logged in"
        condition={isLoggedIn}
        linkTo="/help-page?helpId=679a5ac8d0617161e7c7a265"
        action={isLoggedIn ? 'logged into' : 'log in'}
        userName={userName}
      />
      <ExtensionStatusRow
        title="Pinned"
        condition={isPinnedIn}
        linkTo="/help-page?helpId=679a5b40d0617161e7c7b1fb"
        action={isPinnedIn ? 'pinned' : 'pin'}
        userName={userName}
      />
      <StatusRow title="Checked in today" value={checkedInToday} />
      <StatusRow title="Latest version" value={latestVersion === '0.0.124'} />

      <div className="row mt-1">
        <div className="col-md-9">
          <ul
            style={{
              listStyle: 'none',
              paddingLeft: '10px',
              fontStyle: 'italic',
              marginBottom: 0,
            }}
          >
            <li>{latestVersion}</li>
          </ul>
        </div>
      </div>

      <StatusRow title="Last article logged" value={loggedStatus === '24h'} />

      <div className="row mt-1">
        <div className="col-md-9">
          <ul
            style={{
              listStyle: 'none',
              paddingLeft: '10px',
              fontStyle: 'italic',
              marginBottom: 0,
            }}
          >
            <li>{loggedStatusMap[loggedStatus].message}</li>
          </ul>
        </div>
      </div>

      <div className="row mt-4 pb-2 goal-header font-weight-600">
        <div className="col-md-12">Features enabled</div>
      </div>

      {[
        {
          title: 'Analyze my reading',
          setting: user.profile.sourceSettingsAnalyzeAll,
          handleChange: (checked) =>
            updateSettings('sourceSettingsAnalyzeAll', checked),
        },
        {
          title: 'Credibility layer',
          setting: user.profile.sourceSettingsViewWithPoints,
          handleChange: (checked) =>
            updateSettings('sourceSettingsViewWithPoints', checked),
        },
        {
          title: 'Reading widget',
          setting: user.profile.sourceSettingsReadingWidget,
          handleChange: (checked) =>
            updateSettings('sourceSettingsReadingWidget', checked),
        },
        {
          title: 'Vocabulary',
          setting: user.profile.vocabularyEnabled,
          handleChange: (checked) =>
            updateSettings('vocabularyEnabled', checked),
        },
      ].map((feature, index) => (
        <FeatureRow key={index} isOwner={isOwner} {...feature} />
      ))}
    </React.Fragment>
  );
};

function UserStatusRow({ title, value, linkTo, handleChange }) {
  const [expandRow, setExpandRow] = useState(false);

  return (
    <>
      <div
        className={`row mt-2 ${linkTo ? 'user-status-row' : ''}`}
        onClick={() => {
          if (linkTo) {
            setExpandRow(!expandRow);
          }
        }}
      >
        <div className="col-md-9 extension-status-title">{title}</div>
        <div className="col-md-3 d-flex align-items-center">
          {handleChange ? (
            <Switch
              className=""
              offColor="#65737b"
              offHandleColor="#A4B4BD"
              onColor="#d5e7f4"
              onHandleColor="#1286C8"
              width={30}
              height={16}
              checkedIcon={false}
              uncheckedIcon={false}
              checked={value}
              onChange={(checked, event) => {
                event.preventDefault();
                event.stopPropagation();
                handleChange(checked);
              }}
            />
          ) : (
            renderCheck(value)
          )}
        </div>
      </div>
      {expandRow && (
        <div className="row mt-1">
          <div className="col-md-12 ml-2 font-size-12">{linkTo}</div>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  console.log('ownProps', ownProps);

  return {
    userObject:
      ownProps && ownProps.user ? { user: ownProps.user } : state.user,
    currentUser: state.user.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserStatus);
