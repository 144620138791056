import axios from 'axios';
import { toast } from 'components/elements/molecules/Toast/Toast';

export const LOAD_SUBSCRIPTIONS = 'LOAD_SUBSCRIPTIONS';

export function loadSubscriptions(subscriptions) {
  return {
    type: LOAD_SUBSCRIPTIONS,
    subscriptions,
  };
}

export function getActiveSubscriptions(token, dispatch) {
  return axios({
    method: 'get',
    url: '/api/subscriptions/getActiveSubscriptions',
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      console.log('getActiveSubscriptions responded successfully');
      console.log(response);
      dispatch(loadSubscriptions(response.data.subscriptions));
    })
    .catch((error) => {
      console.log('getActiveSubscriptions responded error');
      console.log(error);
    });
}
